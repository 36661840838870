<template>
  <div>
    <b-list-group>
      <b-list-group-item
        v-for="(item, index) in content"
        :key="'physicalObject' + index"
      >
        <b-row :id="item.id + displayName(item.id)">
          <b-col>
            <b-link
              v-if="names[item.id] != undefined"
              :to="{
                name: 'object',
                params: {
                  id: item.id
                }
              }"
            >
              {{ names[item.id] }}
            </b-link>
          </b-col>
          <b-col sm="auto">
            <b-button
              v-t="'pages.physicalObjects.delete'"
              @click="removePhysicalObject(index)"
            />
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
    <base-auto-complete
      :graph-query="completions"
      :placeholder="$t('pages.physicalObjects.select')"
      :map="nameMap"
      @select="addPhysicalObject"
    >
      <!-- What to show when we have no results -->
      <template v-slot:noResult>
        <span>{{ $t("components.baseList.noResult") }}</span>
      </template>
      <!-- Template for each element in the auto complete -->
      <template v-slot:option="{ option: { name } }">
        <span>{{ name }}</span>
      </template>
      <!-- Template for a selected element in the auto complete -->
      <template v-slot:singleLabel="{ option: { name } }">
        <span>{{ name }}</span>
      </template>
    </base-auto-complete>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "PhysicalObjectsSelector",
  components: {
    BaseAutoComplete: () => import("@/components/BaseAutoComplete")
  },
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      content: this.value,
      names: {},
      completions: gql`
        query searchBySignum($search: String!) {
          completions: findPhysicalObjectsBySigna(search: $search) {
            id
            standardSigna {
              signum1
              signum2
            }
          }
        }
      `
    };
  },
  watch: {
    value() {
      this.content = this.value;
    }
  },
  methods: {
    handleInput(e) {
      this.$emit("input", this.content);
    },
    nameMap(element) {
      let signa = [];
      element.standardSigna.forEach(s => {
        signa.push(s.signum1 + " " + s.signum2);
      });

      return {
        name: signa.join(", "),
        ...element
      };
    },
    addPhysicalObject(element) {
      this.content.push({ id: element.id });
      this.$emit("change");
    },
    removePhysicalObject(index) {
      this.content.splice(index, 1);
      this.$emit("change");
    },
    displayName(id) {
      if (this.names[id] != undefined) return;
      this.$apollo
        .query({
          query: gql`
            query physicalObject($id: ID) {
              physicalObject(id: $id) {
                id
                standardSigna {
                  signum1
                  signum2
                }
              }
            }
          `,
          variables: { id: id }
        })
        .then(result => {
          let signa = [];
          result.data.physicalObject.standardSigna.forEach(s => {
            signa.push(s.signum1 + " " + s.signum2);
          });
          this.$set(
            this.names,
            result.data.physicalObject.id,
            signa.join(", ")
          );
        });
    }
  }
};
</script>
